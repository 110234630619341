import { Helmet } from "react-helmet";

export default function ErrorPage() {
  return (
    <>
      <Helmet>
        <title>DO Share | 404</title>
      </Helmet>

      <div className="relative">
        <div className="py-6 max-w-3xl m-auto text-3xl font-black text-center">
          PAGE NOT FOUND
        </div>
      </div>
    </>
  );
}
