import { Link } from "react-router-dom";
import Logo from "./Logo";

export default function HeaderBar() {
  return (
    <div className="min-h-20 flex justify-center items-center bg-slate-600 text-white">
      <Link to="/" className="flex justify-center items-center">
        <Logo />
        <h2
          className="text-3xl ms-4 font-bold uppercase text-white"
        >
          DO Share !
        </h2>
      </Link>
    </div>
  );
}
