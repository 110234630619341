import { Control, Controller, FieldPathValue } from "react-hook-form";
import Upload from "./Icon/Upload";
import { TFormData } from "../types";
import { formatBytes } from "../utils";
import { useState } from "react";
import classNames from "classnames";

type TProps = {
  control: Control<TFormData>;
  disabled: boolean;
  limit: number;
};

export default function Dropzone({ control, disabled, limit }: TProps) {

  const [
    isActive, 
    setIsActive
  ] = useState<boolean>(false)

  const handleInputChange = (values: FieldPathValue<TFormData, 'files'>, event: React.ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void) => {

    const files = values;
    let size = 0;

    const length = event.target.files?.length;

    if (length) {
      for (let i = 0; i < length; i++) {
        const file = event.target.files?.item(i);

        if (file) {
          if (size + file.size > limit) {
            continue;
          }

          size += file.size || 0;

          files.push(file);
        }
      }
    }

    const unique = files.filter((el, index) => {
      return files.findIndex(obj => obj.size === el.size && obj.name === el.name) === index
    }) 

    onChange(unique);
  }

  const handleInputDrop = (values: FieldPathValue<TFormData, 'files'>, event: React.DragEvent<HTMLLabelElement>, onChange: (...event: any[]) => void) => {

    const files = values;
    let size = 0;

    if (event.dataTransfer.items) {
      for (let i = 0; i < event.dataTransfer.items.length; i++) {

        const file = event.dataTransfer.items[i].getAsFile();

        if (file) {
          if (size + file.size > limit) {
            continue;
          }

          files.push(file)
        }
      }
    } else {
      for (let i = 0; i < event.dataTransfer.files.length; i++) {
        const file = event.dataTransfer.files[i];
        if (size + file.size > limit) {
          continue;
        }

        files.push(file)
      }
    }

    const unique = files.filter((el, index) => {
      return files.findIndex(obj => obj.size === el.size && obj.name === el.name) === index
    })

    onChange(unique);
  }

  return (
    <Controller
      control={control}
      name={"files"}
      render={({ field: { value, onChange, ...field } }) => {
        return (
          <div className="flex items-center justify-center w-full">
            <label
              htmlFor="dropzone-file"
              className={classNames("flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50", { "bg-gray-300 border-gray-600": isActive })}
              onDragEnter={(event) => {
                event.preventDefault();
                event.stopPropagation();

                setIsActive(true)
              }}
              onDragOver={(event) => {
                event.preventDefault();
                event.stopPropagation();

                setIsActive(true)
              }}
              onDragLeave={(event) => {
                event.preventDefault();
                event.stopPropagation();

                setIsActive(false)
              }}
              onDrop={(event) => {
                event.preventDefault();
                event.stopPropagation();

                setIsActive(false)
                handleInputDrop(value, event, onChange)
              }}
            >
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <Upload />
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">Click to upload</span>{' '}
                  or drag and drop
                </p>

                <p className="mb-2 text-xs text-gray-500 dark:text-gray-400">
                  PDF, PNG, JPG or ... no, any files !
                </p>

                <p className="text-lg font-black text-gray-500 dark:text-gray-400">
                  LIMIT: {(formatBytes(limit))}
                </p>
              </div>

              <input 
                {...field} 
                id="dropzone-file" 
                type="file" 
                className="hidden" 
                multiple
                disabled={disabled}
                onChange={(event) => {
                  event.preventDefault();
                  handleInputChange(value, event, onChange)
                }} 
              />
            </label>
          </div>
        );
      }}
    />
  );
}
