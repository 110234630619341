import { formatBytes } from "../utils";
import Download from "./Icon/Download";

type TProps = {
  title: string;
  size: number;
  download: () => void
};

export default function TableDownloadRow({ title, size, download }: TProps) {
  return (
    <tr className="bg-white border-b">
      <th 
        scope="row" 
        className="px-4 py-3 font-medium text-gray-900 text-wrap"
      >
        {title} <br/>
        <span className="font-normal text-gray-500">{formatBytes(size)}</span>
      </th>
      <td className="px-4 py-3 text-end">
        <button 
          type="button"
          className="border-1 rounded-lg px-2 py-2 bg-slate-600 text-white hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-slate-30 focus:ring-slate-300"
          onClick={download}
        >
          <Download />
        </button>
      </td>
    </tr>
  );
}
