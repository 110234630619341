import { Control, Controller } from "react-hook-form";
import { TFormData } from "../types";

type TProps = {
  control: Control<TFormData>;
  name: 'time';
  label: string;
  value: string;
  disabled: boolean;
};

export default function TimeBadge({ control, name, label, value, disabled }: TProps) {
  return (
    <div>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <input
            {...field}
            type="radio"
            id={`time-${value}`}
            value={value}
            className="hidden peer"
            checked={value === field.value}
            disabled={disabled}
          />
        )}
      />
      <label
        htmlFor={`time-${value}`}
        className="inline-flex items-center justify-center w-full p-2 text-sm font-medium text-center bg-white border rounded-lg cursor-pointer text-slate-600 border-slate-600  peer-checked:border-slate-600 peer-checked:bg-slate-600 hover:text-white peer-checked:text-white hover:bg-slate-500"
      >
        {label}
      </label>
    </div>
  );
}
