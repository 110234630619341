import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function MainPage() {
  return (
    <>
      <Helmet>
        <title>DO Share your files</title>
        <meta name="description" content="Quickly upload and transfer files with ease using our temporary file sharing service. Secure and simple, share files and text via a unique link. Only those with the link can access your uploaded information. Perfect for colleagues, friends, and clients." />
      </Helmet>

      <div className="relative">
        <div className="max-w-3xl m-auto px-5 py-6 flex flex-col gap-4 text-center">
          <h1 className="text-lg font-semibold text-gray-900 text-center">Share Your Files Or Text With Ease!</h1>

          <p className="text-gray-500 text-center">Our service provides a convenient way to quickly upload and transfer files on a temporary basis. You can easily upload files, write text, or do both simultaneously, and then share everything via a unique link.</p>

          <div className="text-center py-6">
            <Link 
              to="/create" 
              className="border-1 text-lg font-bold rounded px-4 py-3 bg-slate-600 text-white"
            >
              Create new store
            </Link>
          </div>

          <h2 className="text-lg font-semibold text-gray-900 text-center">Why Choose Our Uploader Service?</h2>

          <ul className="m-auto space-y-1 text-gray-500 list-disc list-inside">
            <li><strong>Ease of Use:</strong> An intuitive interface allows for quick file uploads and transfers.</li>
            <li><strong>Temporary Access:</strong> Links are active for a limited time, after which access to the files is closed.</li>
            <li><strong>Security:</strong> Only those with the link can access the uploaded information.</li>
            <li><strong>Flexibility:</strong> Ability to transfer individual files or text messages.</li>
          </ul>
          
          <p className="text-gray-500 text-center">Take control of your file sharing and management today with our Uploader Service. Experience the perfect blend of convenience and efficiency. :)</p>
        </div>
      </div>
    </>
  );
}

