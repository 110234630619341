import { useForm } from "react-hook-form";
import { useState } from "react";
import classNames from "classnames";

type TContactForm = {
  name: string,
  email: string,
  content: string
}

type TProps = {
  className?: string
  messageSentSuccessfully: () => void
}

export default function ContactForm({className, messageSentSuccessfully}: TProps) {
  
  const [errorsResponse, setErrorsResponse] = useState<{ msg: string; }[]>([])

  const { 
    register,
    handleSubmit,
    reset,
    formState: { errors, isLoading, isSubmitting } 
  } = useForm<TContactForm>({
    defaultValues: {
      name: "",
      email: "",
      content: "",
    },
  });

  const onSubmit = async (data: TContactForm) => {

    // Clear errors
    setErrorsResponse([]);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACK_HOST}/api/v1/contact`, 
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: 'POST',
          body: JSON.stringify({
            name: data.name,
            email: data.email,
            text: data.content
          })
        }
      )

      if (response.ok) {
        reset()
        messageSentSuccessfully()
      } else {
        const contactErrorsResponse = await response.json();
        const contactErrorsMessages: { msg: string }[] = contactErrorsResponse.errors;
        setErrorsResponse(contactErrorsMessages)
      }

    } catch (error: any) {
      console.log(error)
    }
  };

  return (
    <div className={className}>
      <form 
        className="flex flex-col gap-4" 
        onSubmit={handleSubmit(onSubmit)}
      >
        {errorsResponse.length > 0 && (
          errorsResponse.map((x, index) => {
            return (
              <div 
                key={index} 
                className="p-4 text-red-800 rounded-lg bg-red-50" 
                role="alert"
              >
                <span className="font-bold">Error !</span> {x.msg}
              </div>
            )
          })
        )}

        <div>
          <input
            type="text"
            {...register("name", { required: true, maxLength: 64 })}
            className={classNames("block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:ring-slate-500 focus:border-slate-500", {"border-red-500": errors.name})}
            placeholder="Name (required)"
            disabled={isLoading || isSubmitting}
          />
        </div>

        <div>
          <input
            type="text"
            {...register("email", { 
              required: true, 
              maxLength: 64, 
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "invalid email address"
              }
            })}
            className={classNames("block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:ring-slate-500 focus:border-slate-500", {"border-red-500": errors.email})}
            placeholder="Email (required)"
            disabled={isLoading || isSubmitting}
          />
        </div>

        <div>
          <textarea
            {...register("content", { required: true, maxLength: 4096 })}
            rows={5}
            className={classNames("block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:ring-slate-500 focus:border-slate-500", {"border-red-500": errors.content})}
            placeholder="Write your thoughts here..."
            disabled={isLoading || isSubmitting}
          ></textarea>
        </div>

        <div>
          <button
            type="submit"
            className="w-full border-1 text-lg font-bold rounded-lg px-4 py-3 bg-slate-600 text-white text-center"
            disabled={isLoading || isSubmitting}
          >
            Send
          </button>
        </div>
        
      </form>
    </div>
  )
}