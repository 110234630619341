import { formatBytes } from "../utils";
import Trash from "./Icon/Trash";

type TProps = {
  title: string;
  size: number;
  disabled: boolean;
  remove: () => void;
};

export default function TableUploadRow({ title, size, disabled, remove }: TProps) {
  return (
    <tr className="bg-white border-b">
      <th scope="row" className="px-4 py-3 font-medium text-gray-900">
        {title}<br/>
        <span className="font-normal text-gray-500">{formatBytes(size)}</span>
      </th>
      <td className="px-4 py-3 text-end">
        <button 
          className="border-1 rounded-lg px-2 py-2 bg-slate-600 text-white hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-slate-30 focus:ring-slate-300"
          type="button"
          disabled={disabled}
          onClick={remove}
        >
          <Trash />
        </button>
      </td>
    </tr>
  );
}
