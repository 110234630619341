import { Outlet } from 'react-router-dom';
import HeaderBar from './component/HeaderBar';
import Footer from './component/Footer';

export default function Layout() {
  return (
    <div className="flex flex-col h-screen">
      <HeaderBar />
      <div className="flex-grow">
        <div className="container mx-auto">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}