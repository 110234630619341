import { Helmet } from "react-helmet";

export default function TermsAndConditionsPage() {
  return (
    <>
      <Helmet>
        <title>DO Share | Terms And Conditions</title>
      </Helmet>

      <div className="relative">
        <div className="p-6 max-w-3xl m-auto">
          <h1 className="text-xl font-bold text-center pb-6">Terms and Conditions</h1>

          <h3 className="font-bold pb-2">1. Introduction</h3>
          <p className="pb-4">By using this file upload and transfer service DoShare ("Service"), you agree to comply with and be bound by the following terms and conditions ("Terms"). If you do not agree with these Terms, you should not use the Service.</p>


          <h3 className="font-bold pb-2">2. Use of the Service</h3>
          <p className="pb-4">You are responsible for any content you upload, share, or otherwise transmit through the Service. You agree not to use the Service for any illegal activities, including but not limited to the transmission of copyrighted, offensive, or harmful materials.</p>

          <h3 className="font-bold pb-2">3. Temporary Storage and Access</h3>
          <p className="pb-4">The files and content you upload will be stored temporarily and will be accessible only via a unique link. The Service does not guarantee the availability of the uploaded content beyond the specified temporary storage period.</p>

          <h3 className="font-bold pb-2">4. Limitation of Liability</h3>
          <p className="pb-4">You acknowledge and agree that the Service is provided "as is" and "as available" without any warranties of any kind. We do not guarantee the security, reliability, or integrity of the files and content transmitted through the Service.</p>

          <p className="font-semibold pb-4">
            We shall not be liable for any loss, damage, or liability arising from the use of the Service, including but not limited to any direct, indirect, incidental, consequential, or punitive damages. This includes any loss of data, corruption of files, or unauthorized access to the files and content.
          </p>

          <h3 className="font-bold pb-2">5. User Responsibility</h3>
          <p className="pb-4">You are solely responsible for ensuring that the files and content you upload do not violate any laws or infringe on any third-party rights. You agree to indemnify and hold harmless the Service and its operators from any claims, damages, or liabilities resulting from your use of the Service.</p>

          <h3 className="font-bold pb-2">6. Termination</h3>
          <p className="pb-4">We reserve the right to terminate or suspend your access to the Service at any time, without notice, for any reason, including but not limited to a violation of these Terms.</p>

          <h3 className="font-bold pb-2">7. Changes to Terms</h3>
          <p className="pb-4">We reserve the right to modify these Terms at any time. Any changes will be posted on this page, and your continued use of the Service after such changes constitutes your acceptance of the new Terms.</p>

          <h3 className="font-bold pb-2">8. Governing Law</h3>
          <p className="pb-4">These Terms shall be governed by and construed in accordance with the laws of Ukraine, without regard to its conflict of law principles.</p>
        </div>
      </div>
    </>
  );
}
