import { Helmet } from "react-helmet";
import ContactForm from "../component/ContactForm";
import { useState } from "react";

export default function ContactPage() {

  const [isContactFormShown, setIsContactFormShown] = useState<boolean>(false);
  const [isMessageSentSuccessfully, setIsMessageSentSuccessfully] = useState<boolean>(false)

  return (
    <>
      <Helmet>
        <title>DO Share | Contact</title>
        <meta name="description" content="Quickly upload and transfer files with ease using our temporary file sharing service. Secure and simple, share files and text via a unique link. Only those with the link can access your uploaded information. Perfect for colleagues, friends, and clients." />
      </Helmet>

      <div className="relative">
        <div className="flex flex-col gap-4 p-6 max-w-3xl m-auto text-center">
          <div className="m-auto text-3xl font-black text-center">
            Contact
          </div>

          <p className="text-gray-500 text-center">
            Our file transfer service is incredibly useful in today’s digital world. It provides an efficient way to share information, especially when dealing with large files that cannot be sent via email.
          </p>

          <p className="font-bold text-gray-500 text-center">We value your feedback and suggestions! Feel free to share your thoughts, wishes, or any other messages with us. Your input helps us improve and provide the best service possible.</p>

          {!isContactFormShown && (
            <div className="mt-3">
              <button 
                type="button"
                id="contact-form-shown-button"
                className="border-1 text-lg font-bold rounded px-4 py-3 bg-slate-600 text-white"
                onClick={() => {
                  setIsContactFormShown(true)
                  setIsMessageSentSuccessfully(false)
                }}
              >
                Contact Form
              </button>
            </div>
          )}

          {isMessageSentSuccessfully && (
            <div 
              className="mt-3 p-4 text-green-800 rounded-lg bg-green-100" 
              role="alert"
            >
              <span className="font-bold">Success !</span> Message has been sent
            </div>
          )}

          {isContactFormShown && (
            <ContactForm 
              className="mt-3"
              messageSentSuccessfully={() => {
                setIsContactFormShown(false);
                setIsMessageSentSuccessfully(true);
              }} 
            />
          )}
        </div>

      </div>
    </>
  );
}
