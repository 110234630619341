import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer>
      <hr className="mt-2 border-gray-200 sm:mx-auto" />

      <div className="flex flex-col md:flex-row items-center justify-between px-6">
        <span className="block text-sm text-gray-500 text-center my-6">© {(new Date()).getFullYear()} <Link to="/" className="hover:underline">DoShare™</Link>. All Rights Reserved.</span>

        <div className="flex gap-4 md:my-6 mb-6 mt-0">
          <Link to="/create" className="text-gray-500">Create store</Link>
          <Link to="/terms-and-conditions" className="text-gray-500">Terms of Service</Link>
          <Link to="/contact" className="text-gray-500">Contact</Link>
        </div>
      </div>
    </footer>
  )
}